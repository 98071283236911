import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Residence from '../components/Shared/Residence'
import { head } from '../components/head'

export const Head = head('umbracoListingResidencePage')

export default function ListingResidence(props: { data: any }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Residence {...props} />
}
 
export const query = graphql`
  query ResidenceQuery($lang: String!) {
    allUmbracoResidencePage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        name
        contentTypeAlias
        _url
        _urls {
          fr
          en_us
        }
        city {
          name
        }
        partnerName
        partner {
          src
          gatsbyImageData
          media {
            alt
          }
        }
        amenities {
          name
          title
          sortOrder
          contentTypeAlias
        }
        address
        shortName
        shortDescription
        title
        comingSoon
        tag {
          content {
            color
            title
            contentTypeAlias
          }
        }
        thumbnailImages {
          gatsbyImageData(width: 1560, quality: 90)
          media {
            alt
            name
            _url
          }
        }
        items {
          content {
            itemsAccomodation {
              accomodationType {
                name
                accomodationTypeTitle
              }
            }
            newItemsAccomodation {
              content {
                accomodationType {
                  name
                  accomodationTypeTitle
                }
              }
            }
          }
        }
      }
    }
    umbracoListingResidencePage(lang: { eq: $lang }) {
      contentTypeAlias
      _url
      _urls {
        fr
        en_us
      }
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      bottomSEOText
      blocksPush {
        content {
          itemsPush {
            name
            title
            backgroundColor
            title
            subtitle
            link {
              url
              type
              name
            }
            linkEvent
            illustration {
              src
            }
            pushStyle
          }
        }
      }
      eventsListTitle
      eventsListSubtitle
      eventsList {
        content {
          event {
            title
            subtitle
            bg
            _url
            _urls {
              fr
              en_us
            }
            thumbnail {
              media {
                alt
                _url
              }
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            category {
              color
              title
            }
            price
          }
          eventPrice
        }
      }
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
    }
    allUmbracoCity(filter: { lang: { eq: $lang } }) {
      nodes {
        name
        title
      }
    }
    allUmbracoAccomodationType {
      nodes {
        accomodationTypeTitle
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
